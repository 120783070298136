import { Controller } from "@hotwired/stimulus";
import { showAnimatedFlashMessage } from "src/utils/flashMessage";

// Connects to data-controller="my-articles"
export default class MyArticlesController extends Controller {
  static targets = ["articleMenuMore"];

  declare readonly articleMenuMoreTargets: HTMLDivElement[];

  connect(): void {
    document.addEventListener("click", (event: Event) => {
      this.controlConvertToDraftPopupOpened(event);
    });
    document.addEventListener("turbo:frame-render", () => {
      showAnimatedFlashMessage();
    });
    showAnimatedFlashMessage();
  }

  /**
   * 「下書きに戻す」ポップアップの開閉を制御する
   */
  controlConvertToDraftPopupOpened(event: Event): void {
    const { target } = event;
    if (!(target instanceof HTMLElement)) return;

    this.articleMenuMoreTargets.forEach((element) => {
      if (!element.contains(target)) {
        // .article_menu_more以外の要素をクリックした場合はポップアップを閉じる
        element.classList.remove("is_active");
      } else {
        // .article_menu_moreの要素をクリックした場合はポップアップの開閉を切り替え
        element.classList.toggle("is_active");
      }
    });
  }
}
