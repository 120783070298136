import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // メインカテゴリ部分押下時の、サブカテゴリ表示/非表示
  toggleCategory(e: Event): void {
    e.preventDefault();
    const target = e.currentTarget as HTMLElement;
    if (target.parentElement) target.parentElement.classList.toggle('is_open')
  }

  // サブカテゴリさらに表示押下時の挙動
  // サブカテゴリを全て表示し、押したボタンを非表示にする
  subCategoryMore(e: Event): void {
    e.stopPropagation();
    const target = e.currentTarget as HTMLElement;
    const parentElement = target.parentElement;
    if (parentElement) {
      const siblingLiElements = parentElement.querySelectorAll('ul > li');
      siblingLiElements.forEach(li => {
        li.classList.remove('is_hide');
      });
      target.classList.add("is_hide")
    }
  }
}
