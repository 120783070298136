import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="article-detail"
export default class extends Controller {
  static targets: string[] = ["tocIndex", "tocWrap", "tocH2"];
  declare readonly tocIndexTargets: HTMLElement[];
  declare readonly tocWrapTarget: HTMLElement;
  declare readonly tocH2Target: HTMLElement;

  connect(): void {
    // 目次部分の制御
    this.addDetailButton();
  }

  // 「目次」の部分をクリックで、is_index_closeを付け外しを行い、目次の開閉をする
  toggleIndex(event: Event): void {
    this.tocIndexTargets.forEach((element) => {
      element.classList.toggle("is_index_close");
    });

    const menu = this.tocWrapTarget;
    menu.classList.toggle("is_close");
    if (menu.classList.contains("is_close")) {
      menu.style.height = menu.scrollHeight + 'px';
      setTimeout(() => {
        menu.style.height = "0";
      }, 1)
    } else {
      menu.style.height = menu.scrollHeight + 'px';
      setTimeout(() => {
        menu.style.height = 'auto';
      }, 400)
    }
  }

  // 目次の見出しが11以上あった場合、「すべて表示」を描画
  addDetailButton(): void {
    const element = this.tocH2Target;
    const liElements = element.querySelectorAll('li');
    const liCount: number = liElements.length;

    if (liCount >= 11) {
      element.classList.add('is_close');
      let totalHeight = 0;

      for (let i = 0; i < Math.min(10, liCount); i++) {
        const liElement = liElements[i];
        const ulElement = liElement.querySelector('ul');
        let originalHeight: string = '';

        if (ulElement) {
          originalHeight = ulElement.style.height;
          ulElement.style.height = '0';
        }
        totalHeight += liElement.offsetHeight;
        if (ulElement) {
          ulElement.style.height = originalHeight;
        }
      }
      element.style.maxHeight = `${totalHeight}px`;
    }
  }

  // 「すべて表示」ボタンを押した場合、.is_indexDetail_closeを外し、「すべて表示」を削除
  removeDetailButton(): void {
    const list = this.tocH2Target
    list.classList.remove("is_close");
    list.style.maxHeight = `${list.scrollHeight}px`;
  }
}
