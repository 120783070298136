import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="article-sort"
export default class extends Controller {
  static targets = ["hiddenFeaturePageKey", "hiddenSort", "sortSelect"];

  declare readonly hiddenFeaturePageKeyTarget: HTMLInputElement;
  declare readonly hiddenSortTarget: HTMLInputElement;
  declare readonly sortSelectTarget: HTMLInputElement;

  /*
   * 並び替えを選択するとフォームを送信する
   */
  sort(): void {
    this.setSearchParams();
    // ArticleListFilterController#searchArticles をコール
    const event = new CustomEvent("search_articles");
    window.dispatchEvent(event);
  }

  setSearchParams(): void {
    this.hiddenSortTarget.value = this.sortSelectTarget.value;
    if (this.sortSelectTarget.dataset.featurePageKey) {
      this.hiddenFeaturePageKeyTarget.value = this.sortSelectTarget.dataset.featurePageKey
    }
  }
}
