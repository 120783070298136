import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets: string[] = ["reviewItem", "viewMoreReviewButton"];

  declare readonly reviewItemTargets: HTMLElement[];
  declare readonly viewMoreReviewButtonTarget: HTMLElement;

  connect(): void {
    this.hideButtonIfNotEnoughReviews();
    this.addEventTurbo();
  }

  // Turbo のイベントリスナー追加
  addEventTurbo(): void {
    // 初回ページ読み込み時
    document.addEventListener("turbo:load", () => {
      this.hideExtraReviewItems();
      Cookies.remove("show_all_review_cookie");
    });

    // Turbo Frame でページ読み込み時
    document.addEventListener("turbo:frame-load", (event) => {
      if (!event.target || !(event.target instanceof HTMLElement)) return;

      // 記事詳細のTurbo Frame
      if (event.target.id === "article-show") {
        // レビューをもっと見るをクリックしていない場合、4番目以降のレビューを隠す
        const showAllReviewCookie = Cookies.get("show_all_review_cookie");
        if (showAllReviewCookie !== "true") {
          this.hideExtraReviewItems();
        }
      }
    });
  }

  // 4番目以降のレビューを隠す
  hideExtraReviewItems(): void {
    this.reviewItemTargets.forEach((item, index) => {
      if (item instanceof HTMLElement && index > 2) {
        item.classList.add("is_hidden");
      }
    });
  }

  // レビューを全て表示し、ボタンを非表示にする
  showAllReviewItems(event: Event): void {
    if (!(event.currentTarget instanceof HTMLElement)) return;

    const button = event.currentTarget;
    this.reviewItemTargets.forEach((item) => {
      if (item instanceof HTMLElement) {
        item.classList.remove("is_hidden");
      }
    });
    button.style.display = "none";

    // 全て表示状態をcookieに保存
    Cookies.set("show_all_review_cookie", "true");
  }

  // レビューのアイテムが3つ以下の場合、「もっと見る」ボタンを非表示にする
  hideButtonIfNotEnoughReviews(): void {
    const button = this.viewMoreReviewButtonTarget;
    const reviewItems = this.reviewItemTargets;
    if (button instanceof HTMLElement && reviewItems.length <= 3) {
      button.style.display = "none";
    }
  }
}
