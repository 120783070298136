import { Controller } from "@hotwired/stimulus";

import { showAnimatedFlashMessage } from "../src/utils/flashMessage";

// Connects to data-controller="review-reply-modal"
export default class extends Controller {
  static targets = ["replyModal"];

  declare readonly replyModalTargets: HTMLElement[];

  declare textArea: HTMLTextAreaElement;
  declare countNumSpan: HTMLElement;
  declare submitButton: HTMLButtonElement;

  connect(): void {
    this.countInput();
    showAnimatedFlashMessage();
  }

  // 文字数カウントとボタンの活性を制御
  countInput(): void {
    this.replyModalTargets.forEach((modal) => {
      const textArea = modal.querySelector('.write_reply') as HTMLTextAreaElement
      const countNumSpan = modal.querySelector('.textNum') as HTMLElement
      const submitButton = modal.querySelector('#submitButton') as HTMLButtonElement
      const length: number = Array.from(textArea.value).length;
      countNumSpan.textContent = length.toString();
      if (length > 0) {
        submitButton.disabled = false
      } else {
        submitButton.disabled = true
      }

    })
  }
}
