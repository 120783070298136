import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import { internalApiClient } from "src/libs/axios/internalApiClient";

// Connects to data-controller="header"
export default class extends Controller {
  static targets = [
    "mypageMenu",
    "notificationList",
    "keyword",
    "keywordSp",
    "badge"
  ];

  declare readonly notificationListTarget: HTMLElement;
  declare readonly mypageMenuTarget: HTMLElement;
  declare readonly keywordTarget: HTMLInputElement;
  declare readonly keywordSpTarget: HTMLInputElement;
  declare readonly badgeTarget: HTMLElement;

  connect(): void {
    window.addEventListener('click', this.controlHeaderCollapse.bind(this));
  }

  // 通知リスト・マイページメニューを閉じる
  controlHeaderCollapse(event: MouseEvent): void {
    if (!(event.target instanceof Node)) return;

    const targetElement = event.target;

      // 通知リストが開いている場合、通知リスト以外の箇所をクリックすると閉じる
      if (this.notificationListTarget.classList.contains("show") && targetElement !== this.notificationListTarget) {
        const notificationCollapse = Collapse.getInstance(this.notificationListTarget);
        if (notificationCollapse) {
          notificationCollapse.hide();
        }
      }

      // マイページメニューが開いている場合、マイページメニュー以外の箇所をクリックすると閉じる
      if (this.mypageMenuTarget.classList.contains("show") && targetElement !== this.mypageMenuTarget) {
        const myMenuCollapse = Collapse.getInstance(this.mypageMenuTarget);
        if (myMenuCollapse) {
          myMenuCollapse.hide();
        }
      }
  }

  clearInput(): void {
    this.keywordTarget.value = '';
    this.keywordSpTarget.value = '';
  }

  async updateNotificationUnread(): Promise<void> {
      // ベルマークに件数が表示されていれば既読の処理を依頼
      if (this.badgeTarget != null && this.badgeTarget.textContent !== "") {
        await internalApiClient.patch('/api/internal/v1/update_unread')
        // 件数表示をクリア
        this.badgeTarget.style.display = "none";
        this.badgeTarget.textContent = "";
      }
  }
}
