import { Controller } from "@hotwired/stimulus";

import { showAnimatedFlashMessage } from "../src/utils/flashMessage";

// Connects to data-controller="creator-profile"
export default class extends Controller {
  static targets = [
    "articleCard",
    "moreArticleButton"
  ];

  declare readonly articleCardTargets: HTMLElement [];
  declare readonly moreArticleButtonTarget: HTMLElement;

  connect(): void {
    this.displayMoreArticlesButtonIfNeeded();
    showAnimatedFlashMessage();
  }

  // 記事数を数えて、もっと見るボタンを表示するか判定
  displayMoreArticlesButtonIfNeeded(): void {
    const articleCards = this.articleCardTargets;
    const articleCardNum = articleCards.length;

    if (window.matchMedia('(max-device-width: 768px)').matches) {
      if (articleCardNum > 5) {
        this.activateViewMoreArticles();
      }
    } else {
      if (articleCardNum > 10) {
        this.activateViewMoreArticles();
      }
    }
  }

  // もっと見るボタンを表示する
  activateViewMoreArticles(): void {
    const moreArticlesButton = this.moreArticleButtonTarget;
    if (moreArticlesButton) {
      moreArticlesButton.classList.add('is_active');
    }
  }
}
