import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// SP用 絞り込み検索モーダルの制御
// Connects to data-controller="article-list-filter-sp"
export default class extends Controller {
  static targets = [
    "category",
    "dayAll",
    "dayAllInput",
    "days3",
    "days3Input",
    "days7",
    "days7Input",
    "days30",
    "days30Input",
    "tag",

    "subCategoryItem",
    "inputCategory",
    "selectCategoryButton",

    "checkboxDiv",
    "checkboxLabel",
    "checkboxInput"
  ];

  declare readonly categoryTargets: HTMLElement[];
  declare readonly dayAllTarget: HTMLElement;
  declare readonly dayAllInputTarget: HTMLInputElement;
  declare readonly days3Target: HTMLElement;
  declare readonly days3InputTarget: HTMLInputElement;
  declare readonly days7Target: HTMLElement;
  declare readonly days7InputTarget: HTMLInputElement;
  declare readonly days30Target: HTMLElement;
  declare readonly days30InputTarget: HTMLInputElement;
  declare readonly tagTargets: HTMLElement[];

  declare readonly subCategoryItemTargets: HTMLElement[];
  declare readonly inputCategoryTarget: HTMLInputElement;
  declare readonly selectCategoryButtonTarget: HTMLElement;

  declare readonly checkboxDivTarget: HTMLElement;
  declare readonly checkboxLabelTarget: HTMLElement;
  declare readonly checkboxInputTarget: HTMLInputElement;

  declare selectCategoryModal: Modal;

  static values = {
    categoryId: Number,
    days: Number,
    freeWord: String,
    tagWord: String,
    sortKey: String,
  }

  // モーダル起動前からの検索条件
  declare readonly categoryIdValue: number; // 初期値: 0
  declare readonly daysValue: number;
  declare readonly tagWordValue: string;
  declare readonly freeWordValue: string;
  declare readonly sortKeyValue: string;

  // モーダル内で選択した検索条件
  declare selectedCategoryId: number;
  declare selectedDays: number;
  declare selectedTagWord: string;
  declare UnreadCheck: boolean;

  connect(): void {
    // モーダルを開く度に初期値を保持
    this.setCategory();
    this.setDays();
    this.setTagWords();
    this.selectedTagWord = this.tagWordValue;
    // モーダルの中でJSで新たなモーダルを開くために登録しておく
    this.selectCategoryModal = new Modal("#selectCategoryModal");
  }

  // SP用 絞り込み検索モーダル モーダルを開いた際のカテゴリ設定とカテゴリ選択モーダルの制御
  setCategory(): void {
    this.selectedCategoryId = this.categoryIdValue;
    this.subCategoryItemTargets.forEach(li => {
      const categoryId = li.getAttribute('data-article-list-filter-sp-category-id-param')
      if (categoryId === this.selectedCategoryId.toString()) {
        li.classList.add('is_select')
      }
    })
  }

  // SP用 絞り込み検索モーダル モーダルを開いた際の投稿日のラジオボタンの制御
  setDays(): void {

    this.dayAllTarget.classList.remove('is_active');
    this.dayAllInputTarget.checked = false
    this.days3Target.classList.remove('is_active');
    this.days3InputTarget.checked = false
    this.days7Target.classList.remove('is_active');
    this.days7InputTarget.checked = false
    this.days30Target.classList.remove('is_active');
    this.days30InputTarget.checked = false

    if(this.daysValue === 0) {
      this.dayAllTarget.classList.add('is_active');
      this.dayAllInputTarget.checked = true
      this.selectedDays = 0
    } else if (this.daysValue === 3) {
      this.days3Target.classList.add('is_active');
      this.days3InputTarget.checked = true
      this.selectedDays = 3
    } else if (this.daysValue === 7) {
      this.days7Target.classList.add('is_active');
      this.days7InputTarget.checked = true
      this.selectedDays = 7
    } else if (this.daysValue === 30) {
      this.days30Target.classList.add('is_active');
      this.days30InputTarget.checked = true
      this.selectedDays = 30
    }
  }

  setTagWords(): void {
    this.tagWordValue.split(' ').forEach((currentTag) => {
      // this.tagTargets内の各要素に対してループ
      this.tagTargets.forEach((el) => {
        // data-article-list-filter-sp-tag-param属性を取得
        const tagParam = el.getAttribute('data-article-list-filter-sp-tag-param');
        if (tagParam === currentTag) {
          el.classList.add('is_active');
        }
      });
    });
  }

  // SP用 絞り込み検索モーダル カテゴリクリック時の制御
  onCategoryClick(e: any): void {
    // 全てを一旦選択されていない状態にする
    this.subCategoryItemTargets.forEach((li) => {
      li.classList.remove("is_select")
    });

    // 選択されたカテゴリを選択の状態にする(見た目)
    const target = e.currentTarget as HTMLElement;
    if (!target) return

    target.classList.add("is_select");

    // 内部的なカテゴリの付け替え
    this.selectedCategoryId = e.params.categoryId;

    // モーダルを閉じる
    this.selectCategoryModal.hide();

    const subCategoryWrap = target.closest(".js_sub_cat_wrap")
    if (subCategoryWrap) {
      const mainCategoryWrap = subCategoryWrap.previousElementSibling
      if (mainCategoryWrap) {
        const mainCategorySpan = mainCategoryWrap.querySelector(".cat_name")
        const subCategorySpan = target.querySelector(".sub_cat_name")
        if(mainCategorySpan && subCategorySpan) {
          const cat = mainCategorySpan.textContent;
          const subCat = subCategorySpan.textContent;
          const selectCat = `${cat} > ${subCat}`;
          if (selectCat !== "") {
            this.selectCategoryButtonTarget.innerHTML = `${selectCat}<i class="fa-solid fa-circle-xmark icon_reset" data-action="click->article-list-filter-sp#clearCategories"></i>`;
            this.inputCategoryTarget.value = selectCat;
          } else {
            this.clearCategories(e);
          }
        }
      }
    }
  }

  // SP用 絞り込み検索モーダル 投稿日範囲クリック時の制御
  onDaysClick(event: any): void {
    const currentTarget = event.currentTarget as HTMLElement | null;
    if (!currentTarget) return;

    currentTarget.classList.add('is_active');
    if (!currentTarget.firstElementChild) return;

    this.selectedDays = Number((currentTarget.firstElementChild as HTMLInputElement).value);
  }

  // SP用 絞り込み検索モーダル 人気タグクリック時の制御
  onTagClick(event: any): void {
    const currentTarget = event.currentTarget as HTMLElement | null;
    if (!currentTarget) return;

    if(currentTarget.classList.contains('is_active')) {
      currentTarget.classList.remove('is_active');
      const keywordArray = this.selectedTagWord.split(' ').filter(tag => tag !== event.params.tag);
      this.selectedTagWord = keywordArray.join(' ');
    } else {
      this.tagTargets.forEach(function (el) {
        el.classList.remove('is_active');
      });
      currentTarget.classList.add('is_active');
      this.selectedTagWord = event.params.tag
    }
  }

  // 未読の記事のみ表示ボタンクリック時の制御
  onClickUnreadCheck(): void {
    // 未読かどうかのチェックボックスを切り替える
    this.checkboxInputTarget.checked = !this.checkboxInputTarget.checked;
    this.UnreadCheck = this.checkboxInputTarget.checked;
    if (this.checkboxInputTarget.checked) {
      this.checkboxDivTarget.classList.add('is_current');
      this.checkboxLabelTarget.classList.add('is_current');
    } else {
      this.checkboxDivTarget.classList.remove('is_current');
      this.checkboxLabelTarget.classList.remove('is_current');
    }
  }

  // 検索ボタンクリック時の制御
  onSearchClick(): void {
    // 選択したタグとタグ以外の検索ワードをスペースで結合してクエリストリングにセット
    const keywordArray = [];
    if (this.freeWordValue) keywordArray.push(this.freeWordValue);
    if (this.selectedTagWord) keywordArray.push(this.selectedTagWord);

    const searchParams = new URLSearchParams();
    searchParams.append('sort_key', this.sortKeyValue);
    if (keywordArray.length > 0) searchParams.append('keyword', keywordArray.join(' ').trim());
    if (this.selectedCategoryId > 0) searchParams.append('category_id', this.selectedCategoryId.toString());
    if (this.selectedDays) searchParams.append('days', this.selectedDays.toString())
    if (this.UnreadCheck) searchParams.append('unread', this.UnreadCheck.toString())

    window.location.href = "/search?" + searchParams.toString();
  }

  // クリアボタンクリック時の制御
  onClearClick(e: Event): void {
    this.clearCategories(e);
    this.clearDay();
    this.clearTags();
    this.clearUnreadCheck();
  }

  // カテゴリの選択をクリア(すべてを選択する)
  clearCategories(e: Event): void {
    // ×ボタンクリック時にモーダルが開かないようにする
    e.stopPropagation();

    this.subCategoryItemTargets.forEach((li) => {
      li.classList.remove("is_select")
    });

    this.selectCategoryButtonTarget.innerHTML = '<i class="fa-solid fa-list ms-1"></i>カテゴリを選択';
    this.inputCategoryTarget.value = ''

    this.selectedCategoryId = 0
  }

  // 検索の期間をクリア(全期間の選択にする)
  clearDay(): void {
    this.dayAllTarget.classList.add('is_active');
    this.dayAllInputTarget.checked = true
    this.selectedDays = 0

    this.days3Target.classList.remove('is_active');
    this.days3InputTarget.checked = false
    this.days7Target.classList.remove('is_active');
    this.days7InputTarget.checked = false
    this.days30Target.classList.remove('is_active');
    this.days30InputTarget.checked = false

  }

  // タグの選択をクリア
  clearTags(): void {
    this.tagTargets.forEach(function (el) {
      el.classList.remove('is_active');
    });
    this.selectedTagWord = ''
  }

  // 未読の記事のみ表示のチェックボックスをクリア
  clearUnreadCheck(): void {
    this.checkboxInputTarget.checked = false;
    this.UnreadCheck = false;

    this.checkboxDivTarget.classList.remove('is_current');
    this.checkboxLabelTarget.classList.remove('is_current');
  }

  // モーダル内で、カテゴリ選択モーダルを開くための関数
  openCategorySelectModal(): void {
    this.selectCategoryModal.show();
  }
}
