import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sending"
export default class extends Controller {
  static targets = ["loadingScreen"];
  declare readonly loadingScreenTarget: HTMLElement;

  submit(): void {
    this.loadingScreenTarget.style.display = "block";
  }
}