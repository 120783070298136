import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="review-modal-bookmark"
export default class extends Controller {
  static targets = ["checkBox", "label", "checkBoxWrap", "createBookmark"];

  declare readonly checkBoxTarget: HTMLInputElement;
  declare readonly labelTarget: HTMLElement;
  declare readonly checkBoxWrapTarget: HTMLElement;
  declare readonly createBookmarkTargets: HTMLElement [];

  connect(): void {
    this.updateLabelClass();
  }

  updateLabelClass(): void {
    if (this.checkBoxTarget.checked) {
      this.labelTarget.classList.add("is_current");
      this.checkBoxWrapTarget.classList.remove("is_current");
    } else {
      this.labelTarget.classList.remove("is_current");
      this.checkBoxWrapTarget.classList.add("is_current");
    }
  }

  // ブックマークの登録/解除チェックボックスのクラスを切り替える
  toggleCreateBookmark(event: Event): void {
    event.preventDefault();

    this.labelTarget.classList.toggle("is_current");
    this.checkBoxWrapTarget.classList.toggle("is_current");
    this.checkBoxTarget.checked = !this.checkBoxTarget.checked;
  }
}
