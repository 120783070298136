import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bookmark-filter"
export default class extends Controller {
  static targets = [
    "filterWindow",

    // 未読のみ表示チェックボックス
    "checkboxDivPC",
    "checkboxLabelPC",
    "checkboxInputPC",
    "checkboxDivSP",
    "checkboxLabelSP",
    "checkboxInputSP",

    "categorySelectBtn",
    "categorySelectPopup",
    "mainCategory",
    "subCategory",
    "filterInputPC",
    "filterInputSP",
    "closeFilterHistoryBtn",
    "filterHistory",
    "clearAllFilterHistory",
    "historyList",
    "noHistory",
    "historyItem"

  ];

  declare readonly filterWindowTarget: HTMLElement;
  declare readonly checkboxDivPCTarget: HTMLElement;
  declare readonly checkboxLabelPCTarget: HTMLElement;
  declare readonly checkboxInputPCTarget: HTMLInputElement;
  declare readonly checkboxDivSPTarget: HTMLElement;
  declare readonly checkboxLabelSPTarget: HTMLElement;
  declare readonly checkboxInputSPTarget: HTMLInputElement;
  declare readonly categorySelectBtnTarget: HTMLElement;
  declare readonly categorySelectPopupTarget: HTMLElement;
  declare readonly mainCategoryTargets: HTMLElement[];
  declare readonly subCategoryTargets: HTMLElement[];
  declare readonly filterInputPCTarget: HTMLInputElement;
  declare readonly filterInputSPTarget: HTMLInputElement;
  declare readonly closeFilterHistoryBtnTarget: HTMLElement;
  declare readonly filterHistoryTarget: HTMLElement;
  declare readonly clearAllFilterHistoryTarget: HTMLElement;
  declare readonly historyListTarget: HTMLElement;
  declare readonly noHistoryTargets: HTMLElement[];
  declare readonly historyItemTargets: HTMLElement[];

  declare keywordListValue: string[];

  private startPos: number = 0;

  connect(): void {
    this.startPos = 0;

    // SP：画面上部の検索窓の表示を管理
    window.addEventListener("scroll", this.onScroll.bind(this));

    // SP：サブカテゴリホバー時、メインカテゴリに背景色を設定
    if (window.matchMedia('(min-width: 769px)').matches) {
      this.subCategoryTargets.forEach((item) => {
        item.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
        item.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
      });
    }

    window.addEventListener('click', (event) => {
      const targetElement = event.target as HTMLElement;

      // クリックがカテゴリ選択ボタンまたはポップアップ自体の場合は、何もしない
      if (
        this.categorySelectBtnTarget.contains(targetElement) ||
        this.categorySelectPopupTarget.contains(targetElement)
      ) {
        return;
      }

      // その他の場所がクリックされた場合、is_activeクラスを削除
      this.categorySelectBtnTarget.classList.remove('is_active');
      this.categorySelectPopupTarget.classList.remove('is_active');
    });

     // 絞り込み履歴の描画
    this.buildHistoryListHtml();
  }

  // スクロール（上スクロール時絞り込み窓を表示・下スクロール非表示）
  onScroll(): void {
    // 検索窓選択時はスクロールしない
    if (!this.filterWindowTarget.classList.contains("is_fixed")) {
      // スクロール量の取得 iPhoneスクロールバインド対策
      const winScrollTop = window.scrollY;
      if (winScrollTop >= this.startPos && winScrollTop > 100) {
        // 上にスクロールしたとき
        this.filterWindowTarget.classList.add("is_hide");
      } else {
        // 下にスクロールしたとき
        this.filterWindowTarget.classList.remove("is_hide");
      }
      // 現在位置を更新
      this.startPos = winScrollTop;
    }
  }

  // メインカテゴリに背景色をつける
  handleMouseEnter(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const className = Array.from(target.classList).filter(c => c !== 'item').join(' ');
    const relatedItem = this.mainCategoryTargets.filter(el => el.classList.contains(className))[0];
    relatedItem.classList.add('is_hover');
  }

  // メインカテゴリの背景色を削除
  handleMouseLeave(): void {
    this.mainCategoryTargets.forEach((el) => {
      el.classList.remove('is_hover');
    });
  }

  // PC：カテゴリ選択ボタン押下時のトグル動作
  toggleCategoryPopup(): void {
    this.categorySelectBtnTarget.classList.toggle('is_active');
    this.categorySelectPopupTarget.classList.toggle('is_active');
  }

  // SP：検索窓タップで検索履歴エリアを開く
  openFilterHistoryArea(event: Event): void {
    event.preventDefault();
    // 閉じるボタンを表示
    this.closeFilterHistoryBtnTarget.classList.remove("is_hide");
    // 検索履歴表示時は検索窓はスクロールさせない
    this.filterWindowTarget.classList.add("is_fixed");
    // 検索履歴・カテゴリを表示
    this.filterHistoryTarget.classList.remove("is_hide");
  }

  // SP：「閉じる」ボタンクリックで検索履歴エリアを閉じる
  closeFilterHistoryArea(event: Event): void {
    event.preventDefault();
    this.closeFilterHistoryBtnTarget.classList.add("is_hide");
    this.filterWindowTarget.classList.remove("is_fixed");
    this.filterHistoryTarget.classList.add("is_hide");
  }

  // ポップアップから開いたカテゴリをタップした時に実行される関数
  // カテゴリの条件を追加する
  onTapCategory(e: Event): void {
    // aタグクリックによる挙動をストップし、jsでURLを組み立てる
    e.preventDefault();

    // パラメータのcategory_idをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('category_id');

    // パラメータのpageをリセット
    params.delete('page');

    // カテゴリのリンクをクリックしたときidが入る
    const target = (e.currentTarget as HTMLElement);
    const categoryId = target.dataset.bookmarkFilterCategoryIdParam;
    if (categoryId) params.set('category_id', categoryId);
    window.location.href = '/bookmarks?' + params.toString();
  }

  // カテゴリのリセット
  resetCategoryFilter(): void {
    // パラメータのcategory_idをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('category_id');

    // パラメータのpageをリセット
    params.delete('page');

    window.location.href = '/bookmarks?' + params.toString();
  }

  // キーワード検索を実行した時に実行される
  onSearchKeyword(e: Event): void {
    // パラメータのkeywordをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('keyword');

    // パラメータのpageをリセット
    params.delete('page');

    // キーワードを取得
    const target = e.currentTarget as HTMLInputElement
    const isSp = target.dataset.bookmarkFilterSpParam === "true";
    const inputKeyword: string = isSp ? this.filterInputSPTarget.value : this.filterInputPCTarget.value;

    // 入力されたキーワードを保存
    if (inputKeyword !== '') {
      this.saveKeyword(inputKeyword);
       // 重複を排除したリストを作成
      this.keywordListValue = Array.from(new Set(inputKeyword.trim().split(/\s+/)));

      params.set('keyword', this.keywordListValue.join(' '))
    }

    window.location.href = '/bookmarks?' + params.toString();
  }

  // SP：検索履歴からの検索
  onSearchKeywordFromHistory(e: Event): void {
    // パラメータのkeywordをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('keyword');

    const target = e.currentTarget as HTMLElement;
    const keyword = target.innerText;

    params.set('keyword', keyword)
    window.location.href = '/bookmarks?' + params.toString();
  }

  // キーワードの削除
  resetKeywordSearch(e: Event): void {
    // パラメータのkeywordをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('keyword');

    // パラメータのpageをリセット
    params.delete('page');

    // キーワードを取得
    const inputKeyword: string = this.filterInputPCTarget.value !== '' ? this.filterInputPCTarget.value : this.filterInputSPTarget.value;
    this.keywordListValue = Array.from(new Set(inputKeyword.trim().split(/\s+/))); // 重複を排除したリストを作成する

    const target = e.currentTarget as HTMLElement;
    const keyword: string = target.dataset.bookmarkFilterWordParam ?? '';
    this.keywordListValue = this.keywordListValue.filter(word => word !== keyword);

    params.set('keyword', this.keywordListValue.join(' '))
    window.location.href = '/bookmarks?' + params.toString();
  }

  // キーワード検索の入力欄をクリア
  clearInput(): void {
    console.log('clear input');
    this.filterInputPCTarget.value = ''
    this.filterInputSPTarget.value = ''
    this.keywordListValue = []
  }

  // 絞り込み条件のリセット
  resetFilter(e: Event): void {
    e.preventDefault()

    // パラメータのkeywordをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('keyword');
    params.delete('category_id');

    // パラメータのpageをリセット
    params.delete('page');

    window.location.href = '/bookmarks?' + params.toString();
  }

  // 未読の記事のみ表示をクリックした際に実行される関数
  onClickUnreadCheck(e: Event): void {
    e.preventDefault();
    const currentUrl = new URL(window.location.href);
    const bookmarkfilterParams = new URLSearchParams(currentUrl.search);

    // 未読かどうかのチェックボックスを切り替える。
    this.checkboxInputPCTarget.checked = !this.checkboxInputPCTarget.checked;
    this.checkboxInputSPTarget.checked = !this.checkboxInputSPTarget.checked;

    // この処理自体はなくても良いが、ページの読み込み完了までの見た目がスムーズになるようにしている。
    if (this.checkboxInputPCTarget.checked) {
      this.checkboxDivPCTarget.classList.add('is_current');
      this.checkboxLabelPCTarget.classList.add('is_current');
      this.checkboxDivSPTarget.classList.add('is_current');
      this.checkboxLabelSPTarget.classList.add('is_current');
    } else {
      this.checkboxDivPCTarget.classList.remove('is_current');
      this.checkboxLabelPCTarget.classList.remove('is_current');
      this.checkboxDivSPTarget.classList.remove('is_current');
      this.checkboxLabelSPTarget.classList.remove('is_current');
    }

    // パラメータを追加
    bookmarkfilterParams.set('unread', `${this.checkboxInputPCTarget.checked}`);
    // パラメータのpageをリセット
    bookmarkfilterParams.delete('page')
    window.location.href = currentUrl.pathname + '?' + bookmarkfilterParams.toString();
  }

  // キーワード絞り込み履歴の保存
  saveKeyword(keyword: string): void {
    const maxLength = 5; // 最大保存件数
    const serializedArray: string | null = localStorage.getItem("bookmarkFilterKeywordHistory");

    const keywordArray: string[] = serializedArray ? JSON.parse(serializedArray) : []

    // すでに保存済のワードであれば先頭に移動させるために一旦削除
    const keyIndex: number = keywordArray.indexOf(keyword);
    if (keyIndex > -1) keywordArray.splice(keyIndex, 1);

    // 先頭に追加
    keywordArray.unshift(keyword);

    // Max５件超過分は削除
    if (keywordArray.length > maxLength) keywordArray.splice(maxLength);

    // 保存
    const newSerializedArray: string = JSON.stringify(keywordArray);
    localStorage.setItem("bookmarkFilterKeywordHistory", newSerializedArray);
  }

  // ローカルストレージの検索ワード一覧を取得し、検索履歴のHTMLを生成する
  buildHistoryListHtml(): void {
    const serializedArray = localStorage.getItem("bookmarkFilterKeywordHistory");
    const keywordArray: [] = serializedArray ? JSON.parse(serializedArray) : [];

    if (keywordArray.length === 0 && this.noHistoryTargets.length === 0) {
      // 検索履歴がない場合
      const noHistoryHtml = `
        <li class="search_history_item no_border" data-bookmark-filter-target="noHistory">
          <p class="search_history_word">履歴はありません</p>
        </li>
      `;

      this.clearAllFilterHistoryTarget.style.display = "none";

      // 出力先の要素（ul）に追加
      this.historyListTarget.innerHTML += noHistoryHtml;
      return;
    }

    let historyHtml = '';

    // 検索ワードのリストから li タグを生成
    keywordArray.forEach((keyword: string) => {
      historyHtml += `
        <li class="search_history_item" data-bookmark-filter-target="historyItem">
          <button class="search_history_word" data-action="click->bookmark-filter#onSearchKeywordFromHistory">
            ${keyword}
          </button>
          <i class="fa-solid fa-xmark btn_clearHistoryItem" data-action="click->bookmark-filter#clearHistoryItem"></i>
        </li>
      `;

      this.clearAllFilterHistoryTarget.style.display = "";

      // 出力先の要素（ul）に追加
      this.historyListTarget.innerHTML = historyHtml;
    });
  }

  // SP：絞り込み履歴の削除
  clearHistoryItem(event: Event): void {
    event.preventDefault();

    if (!(event.currentTarget instanceof HTMLElement)) return;
    const targetElement = event.currentTarget;

    const historyItemLi = targetElement.closest(".search_history_item");
    if (!(historyItemLi instanceof HTMLElement)) return;

    if (historyItemLi.firstChild === null) return;

    const button = historyItemLi.querySelector("button.search_history_word");
    if (!(button instanceof HTMLButtonElement)) return;
    const keywordToDelete = button.innerText;

    if (!localStorage.getItem("bookmarkFilterKeywordHistory")) return;

    // ローカルストレージからキーワードのリストを取得
    const storedValue = localStorage.getItem("bookmarkFilterKeywordHistory");
    const storedKeywords: string[] = storedValue ? JSON.parse(storedValue) : [];

    // 削除対象のキーワードを配列から取り除く
    const updatedKeywords = storedKeywords.filter((keyword: string) => keyword !== keywordToDelete);

    // 更新されたキーワードのリストをローカルストレージに再保存
    localStorage.setItem("bookmarkFilterKeywordHistory", JSON.stringify(updatedKeywords));

    historyItemLi.style.display = "none";

    // もし履歴がすべてなくなったら、「履歴はありません」を描画
    if (updatedKeywords.length === 0) {
      this.buildHistoryListHtml();
    }
  }

  // すべての履歴を削除
  clearAllHistoryItem(event: Event): void {
    event.preventDefault();

    // 履歴を全て非表示にする
    this.historyItemTargets.forEach((item) => {
      item.style.display = "none";
    });

    // ローカルストレージをリセットし、「履歴はありません」を描画
    const updatedKeywords: string[] = [];
    localStorage.setItem("bookmarkFilterKeywordHistory", JSON.stringify(updatedKeywords));
    if (updatedKeywords.length === 0) {
      this.buildHistoryListHtml();
    }
  }
}
