import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="article-comfirm"
export default class extends Controller {
  static targets: string[] = ["agreeToTheTerms", "submitButton"];
  declare readonly agreeToTheTermsTargets: HTMLElement[];
  declare readonly submitButtonTargets: HTMLElement[];

  toggleButton(): void {
    this.agreeToTheTermsTargets.forEach((agreeToTheTermsTarget) =>
      agreeToTheTermsTarget.classList.toggle("is_current"),
    );

    this.submitButtonTargets.forEach((submitButton) => submitButton.classList.toggle("is_disable"));
  }
}
