import { Controller } from "@hotwired/stimulus";
import { internalApiClient } from "src/libs/axios/internalApiClient";

// Connects to data-controller="rewards"
export default class extends Controller {
  static targets = [
    "stopPointModal",
    "stopPointLi",
    "stopPointLabel",
    "stopPointInput",
    "deletePaymentAccountLi",
    "deletePaymentAccountLabel",
    "deletePaymentAccountInput",
    "stopPointButton"
  ];

  declare readonly stopPointModalTarget: HTMLElement;
  declare readonly stopPointLiTarget: HTMLElement;
  declare readonly stopPointLabelTarget: HTMLElement;
  declare readonly stopPointInputTarget: HTMLInputElement;
  declare readonly deletePaymentAccountLiTarget: HTMLElement;
  declare readonly deletePaymentAccountLabelTarget: HTMLElement;
  declare readonly deletePaymentAccountInputTarget: HTMLInputElement;
  declare readonly stopPointButtonTarget: HTMLButtonElement;

  connect(): void {
    this.resetCheckBox();
  }

  updateButtonState(e: Event): void {
    const stopPointChecked = this.stopPointInputTarget.checked;
    const deletePaymentAccountChecked = this.deletePaymentAccountInputTarget.checked;
    const button = this.stopPointButtonTarget;

    if (stopPointChecked){
      this.stopPointLiTarget.classList.add("is_current");
      this.stopPointLabelTarget.classList.add("is_current");
    } else {
      this.stopPointLiTarget.classList.remove("is_current");
      this.stopPointLabelTarget.classList.remove("is_current");
    }

    if (deletePaymentAccountChecked){
      this.deletePaymentAccountLiTarget.classList.add("is_current");
      this.deletePaymentAccountLabelTarget.classList.add("is_current");
    } else {
      this.deletePaymentAccountLiTarget.classList.remove("is_current");
      this.deletePaymentAccountLabelTarget.classList.remove("is_current");
    }

    if (stopPointChecked && deletePaymentAccountChecked) {
      button.classList.remove("is_disable");
    } else {
      button.classList.add("is_disable");
    }
  }

  resetCheckBox(): void {
    const stopPointModal = this.stopPointModalTarget;
    stopPointModal.addEventListener('hidden.bs.modal', () => {
      this.stopPointInputTarget.checked = false;
      this.deletePaymentAccountInputTarget.checked = false;
      this.stopPointButtonTarget.classList.add("is_disable");
      this.stopPointLiTarget.classList.remove("is_current");
      this.stopPointLabelTarget.classList.remove("is_current");
      this.deletePaymentAccountLiTarget.classList.remove("is_current");
      this.deletePaymentAccountLabelTarget.classList.remove("is_current");
    });
  }

  async stopPoint(): Promise<void> {
    await internalApiClient.patch('/rewards/stop_point')
  }
}
