import { Controller } from "@hotwired/stimulus";

// PC/SP 検索結果画面の検索条件アイコンの制御
// Connects to data-controller="article-list-filter-item"
export default class extends Controller {
  // Add missing imports
  static values = {
    categoryId: Number,
    day: Number,
    freeWordList: Array,
    tagWordList: Array,
    sortKey: String,
  }

  declare categoryIdValue: number;
  declare dayValue: number;
  declare freeWordListValue: string[];
  declare tagWordListValue: string[];
  declare readonly sortKeyValue: string;

  // li.filter_item カテゴリの「×」マーククリック時
  resetCategorySearch(event: MouseEvent): void {
    this.categoryIdValue = 0;
    this.searchArticles();
  }

  // li.filter_item 投稿日の「×」マーククリック時
  resetDaySearch(event: MouseEvent): void {
    this.dayValue = 0;
    this.searchArticles();
  }

  // li.filter_item 検索ワードの「×」マーククリック時
  resetFreeWordSearch({ params }: { params: any }): void {
    // クリックした検索ワードを削除
    this.freeWordListValue = this.freeWordListValue.filter(n => n !== params.word);
    this.searchArticles();
  }

  // li.filter_item タグの「×」マーククリック時
  resetTagWordSearch({ params }: { params: any }): void {
    // クリックした検索ワードを削除
    this.tagWordListValue = this.tagWordListValue.filter(n => n !== params.word);
    this.searchArticles();
  }

  // 検索
  searchArticles(): void {
    // 残った検索ワードとタグをスペースで結合してクエリストリングにセット
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    if (this.categoryIdValue > 0) {
      searchParams.set('category_id', this.categoryIdValue.toString());
    } else {
      searchParams.delete('category_id');
    }
    if (this.dayValue > 0) {
      searchParams.set('days', this.dayValue.toString());
    } else {
      searchParams.delete('days');
    }
    if (this.freeWordListValue.length !== 0 || this.tagWordListValue.length !== 0) {
      searchParams.set('keyword', this.freeWordListValue.concat(this.tagWordListValue).join(' '));
    } else {
      searchParams.delete('keyword');
    }
    searchParams.set('sort_key', this.sortKeyValue);

    window.location.href = "/search?" + searchParams.toString();
  }
}
