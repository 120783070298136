import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="review-reaction"
export default class extends Controller {
  static targets = ["thanksWrap"];

  declare readonly thanksWrapTarget: HTMLElement;

  declare observer: IntersectionObserver;

  connect(): void {
    // ページ読み込み時の初期化処理
    this.observer = new IntersectionObserver(this.activate.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });

    this.observer.observe(this.thanksWrapTarget);
  }

  activate(entries: IntersectionObserverEntry[]): void {
    // 要素がビューポートに完全に表示されたかチェック
    entries.forEach((entry: IntersectionObserverEntry) => {
      const target =  entry.target as HTMLElement
      if (entry.isIntersecting && target.dataset.thanksPresent === "true") {
        entry.target.classList.add('is_active');
      }
    });
  }
}
