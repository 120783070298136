import { Controller } from "@hotwired/stimulus";
import { internalApiClient } from "src/libs/axios/internalApiClient";

// Connects to data-controller="notification-setting"
export default class NotificationSettingController extends Controller {

  static targets = ["errorMsg"];

  declare readonly errorMsgTarget: HTMLElement;

  async updateSetting(e: Event): Promise<void> {
    if (e.target == null) return;

    const optionTag = e.target as HTMLOptionElement

    const id = optionTag.id
    const value = optionTag.value
    const response = await internalApiClient.patch('/api/internal/v1/notification_settings/update', {
      [id]: value
    })

    // 成功したらアニメーションを表示させる
    if (response.status === 200) {
      this.displayAnimation(e);
    } else {
      this.displayFaliedAnimation();
      console.log('Something went wrong!', response.status, response.statusText);
    }
  }

  // 変更された要素の変更しましたメッセージを2秒表示
  displayAnimation(e: Event): void {
    const optionTag = e.target as HTMLOptionElement
    const saveMessage =  optionTag.nextElementSibling
    if (saveMessage == null) return;

    saveMessage.classList.add('now_saved');

    setTimeout(function() {
      saveMessage.classList.remove('now_saved');
    }, 2000);
  }

  // 失敗時のエラーメッセージの表示
  displayFaliedAnimation(): void {
    const errorMessage = this.errorMsgTarget;

    errorMessage.classList.add('is_active');
    setTimeout(function() {
      errorMessage.classList.remove('now_saved');
    }, 2000);
  }
}
