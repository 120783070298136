import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-tag-list"
export default class extends Controller {
  static targets = ["tagArea"];

  declare readonly tagAreaTarget: HTMLElement;

  // SP用 開閉するタグリストの制御
  connect(): void {
    this.initDispayTagList();
  }

  initDispayTagList(): void {
    // レビューの高さが4行以上の場合、一部を隠して「もっと見る」ボタンを出す
    const boxHeight = this.tagAreaTarget.scrollHeight;
    // もしタグの高さが1行（90px）以下の場合、ボタンなし。以上はボタンあり
    if (boxHeight <= 90) {
      // ボタンなし用のclassを付ける
      this.tagAreaTarget.classList.add('is_btn_less');
    } else {
      // ボタンあり・閉じる用のclassを追加
      this.tagAreaTarget.classList.add('is_close');
      this.tagAreaTarget.style.maxHeight = "80" + 'px';
    }
  }

  openArea(): void {
    this.tagAreaTarget.style.maxHeight = this.tagAreaTarget.scrollHeight + 'px';
    this.tagAreaTarget.classList.remove('is_close');
    this.tagAreaTarget.classList.add('is_open');
  }

  closeArea(): void {
    this.tagAreaTarget.style.maxHeight = "80" + 'px';
    this.tagAreaTarget.classList.remove('is_open');
    this.tagAreaTarget.classList.add('is_close');
  }
}
