import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link"
export default class extends Controller {

  // aタグで post するだけ
  postForm(event) {
    const form = document.createElement('form');
    form.action = event.target.href;
    form.method = 'post';
    document.body.appendChild(form);
    form.submit();
  }
}