import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets: string[] = ["mainCategory", "subCategory"];

  declare readonly mainCategoryTargets: HTMLElement[];
  declare readonly subCategoryTargets: HTMLElement[];

  connect(): void {
    this.displayCategories();
  }

  // SPモーダル用の表示
  displayCategories(): void {
    this.mainCategoryTargets.forEach(li => {
      li.classList.add("is_open")
    });
    this.subCategoryTargets.forEach(li => {
      li.classList.remove("is_hide")
    });
  }
}
