import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  copyLink(): void {
    const urlCopyButton = this.element as HTMLButtonElement
    const url = urlCopyButton.dataset.url

    if (url) {
      navigator.clipboard.writeText(url).then(() => {
        this.insertFlashMessage('この記事のリンクをコピーしました！');
      }).catch(err => {
        console.error("リンクのコピーに失敗しました: ", err);
      });
    }
  }

  // jsからフラッシュメッセージを表示する
  // TODO: 他の箇所でも使用することがあれば共通化する
  insertFlashMessage(message: string): void {
    const wrapperElement = document.getElementById('wrapper');
    if (wrapperElement) {
      const flashMessageDiv = document.createElement('p');
      flashMessageDiv.classList.add('flashMsg', 'success', 'js_flashMsg');
      flashMessageDiv.textContent = message;

      if (wrapperElement.parentNode == null) return;

      wrapperElement.parentNode.insertBefore(flashMessageDiv, wrapperElement);

      setTimeout(() => {
        flashMessageDiv.classList.add('is_active');
      }, 100);

      setTimeout(() => {
        flashMessageDiv.classList.remove('is_active');
        setTimeout(() => {
          flashMessageDiv.remove();
        }, 1000);
      }, 4000);
    } else {
      console.error("wrapper要素が見つからないため、フラッシュメッセージを表示できませんでした");
    }
  }
}
