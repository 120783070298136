import { Controller } from "@hotwired/stimulus";

import { showAnimatedFlashMessage } from "../src/utils/flashMessage";

// Connects to data-controller="review-modal"
export default class extends Controller {
  static targets = ["countNum", "countField", "submitButton"];

  declare readonly submitButtonTarget: HTMLButtonElement;
  declare readonly countNumTarget: HTMLElement;
  declare readonly countFieldTarget: HTMLTextAreaElement;

  declare isGoodSubmitButton: boolean;
  declare disactiveConditon: boolean;

  connect(): void {
    this.countInput();
    showAnimatedFlashMessage();
  }

  // 文字数カウント
  countInput(): void {
    // 現在の入力文字数を取得 絵文字を1文字としてカウントするために配列に変換
    const inputValue: string = this.countFieldTarget.value;
    const length: number = Array.from(inputValue).length;
    this.countNumTarget.textContent = length.toString();

    if (this.submitButtonTarget.textContent === null) return;

    // 改行文字のみの入力をチェック
    const trimmedInputValue: string = inputValue.replace(/[\r\n]+/g, '');
    const trimmedLength: number = Array.from(trimmedInputValue).length;

    this.isGoodSubmitButton = this.submitButtonTarget.textContent.includes('いいねを送る');
    this.disactiveConditon = !this.isGoodSubmitButton && (length < 20 || length > 500) ||
                             this.isGoodSubmitButton && (length > 0 && (length < 20 || length > 500)) ||
                             (length > 0 && trimmedLength === 0)


    if (this.disactiveConditon) {
      this.disactivateButtonState();
      return;
    }
    this.activateButtonState();
  }

  // 送信ボタンを活性にする
  activateButtonState(): void {
    this.submitButtonTarget.disabled = false;
  }

  // 送信ボタンを非活性にする
  disactivateButtonState(): void {
    this.submitButtonTarget.disabled = true;
  }
}
