import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="payment-account-minor"
export default class PaymentAccountController extends Controller {
  static targets = [
    "checkboxArea",
    "checkboxLabel",
    "checkbox"
  ];

  declare readonly checkboxAreaTarget: HTMLElement;
  declare readonly checkboxLabelTarget: HTMLElement;
  declare readonly checkboxTarget: HTMLInputElement;

  toggleMinorConfirmation(e: Event): void {
    // controllerをアタッチしている外側の要素取得のため、querySelectorを使用
    const submitButton = document.querySelector('#submitBtn');
    if (!submitButton) {
      return;
  }

    if(this.checkboxTarget.checked){
      this.checkboxAreaTarget.classList.add("is_current")
      this.checkboxLabelTarget.classList.add("is_current")
      submitButton.classList.remove('is_disable');
    } else {
      this.checkboxAreaTarget.classList.remove("is_current")
      this.checkboxLabelTarget.classList.remove("is_current")
      submitButton.classList.add('is_disable');
    }
  }
}
