import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="payment-failure-modal"
export default class extends Controller {

  declare paymentFailureModal: Modal;

  connect(): void {
    this.paymentFailureModal = new Modal("#paymentErrorModal");
    this.displayPaymentFailureModal();
  }

  // 支払い失敗したユーザーに対してモーダルを表示する
  displayPaymentFailureModal(): void {
    this.paymentFailureModal.show()
  }
}
