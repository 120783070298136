import { Controller } from "@hotwired/stimulus";

import { showAnimatedFlashMessage } from "../src/utils/flashMessage";

// Connects to data-controller="creator-review"
export default class extends Controller {

  connect(): void {
    showAnimatedFlashMessage();
  }
}
