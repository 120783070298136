import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="page-transition-alert"
export default class PageTransitionAlertController extends Controller {
  static targets = ["transitionModal", "transitionAlertLink", "validTransitionLink"];

  declare readonly transitionModalTarget: HTMLDivElement;
  declare readonly transitionAlertLinkTarget: HTMLAnchorElement;
  // submitボタンなど、クリックしてもアラートモーダルを表示させたくない要素に設定する
  declare readonly validTransitionLinkTargets: HTMLElement[];

  declare formChangeFlag: boolean;

  declare alertModal: Modal;

  connect(): void {
    this.alertModal = new Modal(`#${this.transitionModalTarget.id}`);
    this.formChangeFlag = false;
    this.#addPageTransitionListener();
  }

  enableChangeFlag(): void {
    this.formChangeFlag = true;
  }

  /**
   * フォームに差分がある場合に、aタグをクリックすると離脱防止モーダルを表示する
   */
  #addPageTransitionListener(): void {
    // aタグで離脱防止モーダル表示
    document.querySelectorAll("a").forEach((anchor) => {
      const isBlankTarget = anchor.getAttribute("target")?.includes("_blank");
      // 別タブで開くリンクは除外
      if (isBlankTarget) return;
      // アラートモーダルの「OK」ボタンや、submitボタンなど除外
      if (anchor === this.transitionAlertLinkTarget || this.validTransitionLinkTargets.includes(anchor)) return;
      // ページトップに戻る矢印も除外
      if (anchor.id === "pagetop") return;

      anchor.onclick = (e) => {
        if (!this.formChangeFlag) return;
        e.preventDefault();
        this.transitionAlertLinkTarget.href = anchor.href;
        this.alertModal.show();
      };
    });

    // 検索フォームsubmitで離脱防止モーダル表示
    // 検索フォームはcontrollerのスコープ外なので、targetではなくgetElementByIdで要素取得する
    const searchForm = document.getElementById("articleSearchForm");
    if (!(searchForm instanceof HTMLFormElement)) return;

    searchForm?.addEventListener("submit", (e: Event) => {
      if (!this.formChangeFlag) return;
      e.preventDefault();
      this.transitionAlertLinkTarget.href = "";
      this.transitionAlertLinkTarget.onclick = (clickEvent: Event) => {
        searchForm.submit();
      };
      this.alertModal.show();
    });
  }
}
