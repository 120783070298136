import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "searchWindow",
    "cancelledBtn",
    "clearAllHistory",
    "historyWindow",
    "historyList",
    "noHistory",
    "historyItem",
    "searchedWord",
    "firstBookmarkPopover"
  ];

  declare readonly searchWindowTarget: HTMLElement;
  declare readonly cancelledBtnTarget: HTMLElement;
  declare readonly clearAllHistoryTarget: HTMLElement;
  declare readonly historyWindowTarget: HTMLElement;
  declare readonly historyListTarget: HTMLElement;
  declare readonly noHistoryTargets: HTMLElement[];
  declare readonly historyItemTargets: HTMLElement[];
  declare readonly searchedWordTarget: HTMLElement;
  declare readonly firstBookmarkPopoverTarget: HTMLElement;

  private startPos: number = 0;

  connect(): void {
    this.startPos = 0;
    window.addEventListener("scroll", this.onScroll.bind(this));

    // 検索履歴の表示
    this.buildHistoryListHtml();
  }

  /**
   * スクロール（上スクロール時検索窓を表示・下スクロール非表示）
   */
  onScroll(): void {
    // 検索窓選択時はスクロールしない
    if (!this.searchWindowTarget.classList.contains("is_fixed")) {
      // スクロール量の取得 iPhoneスクロールバインド対策
      const winScrollTop = window.scrollY;
      if (winScrollTop >= this.startPos && winScrollTop > 100) {
        // 上にスクロールしたとき
        this.searchWindowTarget.classList.add("is_hide");
      } else {
        // 下にスクロールしたとき
        this.searchWindowTarget.classList.remove("is_hide");
      }
      // 現在位置を更新
      this.startPos = winScrollTop;
    }
  }

  /**
   * 【SP】検索窓タップで検索履歴エリアを開く
   */
  toggleSearchArea(event: Event): void {
    event.preventDefault();
    // 閉じるボタンを表示
    this.cancelledBtnTarget.classList.remove("is_hide");
    // 検索履歴表示時は検索窓はスクロールさせない
    this.searchWindowTarget.classList.add("is_fixed");
    // 検索履歴・カテゴリを表示
    this.historyWindowTarget.classList.remove("is_hide");
  }

  /**
   * 【SP】キャンセルボタンクリックで検索履歴エリアを閉じる
   */
  closeSearch(event: Event): void {
    event.preventDefault();
    this.cancelledBtnTarget.classList.add("is_hide");
    this.searchWindowTarget.classList.remove("is_fixed");
    this.historyWindowTarget.classList.add("is_hide");
  }

  /**
   * ローカルストレージの検索ワード一覧を取得し、検索履歴のHTMLを生成する
   * app/views/shared/_header.html.erb 内の ul.search_history 配下にリストを追加
   */
  buildHistoryListHtml(): void {
    const serializedArray = localStorage.getItem("keywordArray");
    const keywordArray: [] = serializedArray ? JSON.parse(serializedArray) : [];

    if (keywordArray.length === 0 && this.noHistoryTargets.length === 0) {
      // 検索履歴がない場合
      const li = document.createElement("li");
      li.classList.add("search_history_item", "no_border");
      li.setAttribute("data-header-search-sp-target", "noHistory");

      const p = document.createElement("p");
      p.classList.add("search_history_word");
      p.textContent = "履歴はありません";

      li.appendChild(p);

      this.clearAllHistoryTarget.style.display = "none";

      // 出力先の要素（ul）に追加
      this.historyListTarget.appendChild(li);
      return;
    }

    // 検索ワードのリストから li タグを生成
    keywordArray.forEach((keyword: string) => {
      const li = document.createElement("li");
      li.classList.add("search_history_item");
      li.setAttribute("data-header-search-sp-target", "historyItem");

      const a = document.createElement("a");
      a.setAttribute("data-action", "click->article-list-filter#searchArticles");
      a.setAttribute("data-article-list-filter-sp-mode-param", "true");
      a.setAttribute("data-header-search-sp-target", "searchedWord");
      a.classList.add("search_history_word");
      a.textContent = `${keyword}`;

      const i = document.createElement("i");
      i.classList.add("fa-solid", "fa-xmark", "btn_clearHistoryItem");
      i.setAttribute("data-action", "click->header-search-sp#clearHistoryItem");

      li.appendChild(a);
      li.appendChild(i);

      this.clearAllHistoryTarget.style.display = "";

      // 出力先の要素（ul）に追加
      this.historyListTarget.appendChild(li);
    });
  }

  clearHistoryItem(event: Event): void {
    event.preventDefault();

    if (!(event.currentTarget instanceof HTMLElement)) return;
    const targetElement = event.currentTarget;

    const historyItemLi = targetElement.closest(".search_history_item");
    if (!(historyItemLi instanceof HTMLElement)) return;

    const keywordToDeleteElement = this.targets.find("searchedWord");
    if (!(keywordToDeleteElement instanceof HTMLElement)) return;

    const keywordToDelete: string = keywordToDeleteElement.textContent ?? "";

    if (!localStorage.getItem("keywordArray")) return;

    // ローカルストレージからキーワードのリストを取得
    const storedValue = localStorage.getItem("keywordArray");
    const storedKeywords: string[] = storedValue ? JSON.parse(storedValue) : [];

    // 削除対象のキーワードを配列から取り除く
    const updatedKeywords = storedKeywords.filter((keyword: string) => keyword !== keywordToDelete);

    // 更新されたキーワードのリストをローカルストレージに再保存
    localStorage.setItem("keywordArray", JSON.stringify(updatedKeywords));

    historyItemLi.style.display = "none";

    // もし履歴がすべてなくなったら、「履歴はありません」を描画
    if (updatedKeywords.length === 0) {
      this.buildHistoryListHtml();
    }
  }

  // すべての履歴を削除するボタン押下時
  clearAllHistoryItem(event: Event): void {
    event.preventDefault();

    // 履歴を全て非表示にする
    this.historyItemTargets.forEach((item) => {
      item.style.display = "none";
    });

    // ローカルストレージをリセットし、「履歴はありません」を描画
    const updatedKeywords: string[] = [];
    localStorage.setItem("keywordArray", JSON.stringify(updatedKeywords));
    if (updatedKeywords.length === 0) {
      this.buildHistoryListHtml();
    }
  }

  closeFirstBookmarkPopover(): void {
    this.firstBookmarkPopoverTarget.classList.remove('is_show');
  }

  showFirstBookmarkPopoverIfNeeded(): void {
    const flagKey = "firstBookmarkShown";

    // PCまたはすでにフラグがある場合にはポップオーバーの表示はしない
    if (window.innerWidth > 768 || localStorage.getItem(flagKey)) {
      return;
    }

    // ポップオーバーを表示し、フラグを立てる
    this.firstBookmarkPopoverTarget.classList.add("is_show");
    localStorage.setItem(flagKey, "true");
  }
}
