import { Controller } from "@hotwired/stimulus";
import Cookies from 'js-cookie';

// Connects to data-controller="payment-process"
export default class extends Controller {
  static targets = ["paymentProcessWrap"];

  declare readonly paymentProcessWrapTarget: HTMLElement;

  // お支払いまでの流れの開閉処理
  // 同時にcookieの削除・追加を行う
  togglePaymentProcess(): void {
    this.paymentProcessWrapTarget.classList.toggle("is_close");
    const paymentProcessCookie = Cookies.get('payment_process_cookie');
    if (paymentProcessCookie === "is_close") {
      Cookies.remove('payment_process_cookie');
    } else {
      Cookies.set('payment_process_cookie', 'is_close', {
        expires: 30
      });
    }
  }
}
