import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="article-show"
export default class extends Controller {
  static targets = ["headerContainer", "fixedFooter", "articleMenuMore"];

  declare readonly headerContainerTarget: HTMLElement;
  declare readonly fixedFooterTarget: HTMLElement;
  declare readonly articleMenuMoreTarget: HTMLElement;
  private startPos: number = 0;

  connect(): void {
    window.addEventListener("scroll", () => {
      this.handleScroll();
    });
  }

  handleScroll(): void {
    const winScrollTop = window.scrollY;

    if (winScrollTop >= this.startPos && winScrollTop > 100) {
      // 下にスクロールしたとき
      this.headerContainerTarget.classList.add("is_hide");
      this.fixedFooterTarget.classList.add("is_hide");
    } else {
      // 上にスクロールしたとき
      this.headerContainerTarget.classList.remove("is_hide");
      this.fixedFooterTarget.classList.remove("is_hide");
    }

    // ページの下までスクロールした場合（少し余白を持たせる）
    if (winScrollTop + window.innerHeight >= document.body.offsetHeight - 150) {
      this.fixedFooterTarget.classList.remove("is_hide");
    }

    // 現在位置を更新
    this.startPos = winScrollTop;
  }

  toggleArticleMenuMore(): void {
    this.articleMenuMoreTarget.classList.toggle("is_active");
  }
}
