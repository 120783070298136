import { Controller } from "@hotwired/stimulus";

type SearchEvent = Event & {
  params: {
    spMode?: string;
    categoryId: string;
    days: string;
    tag: string;
  };
  currentTarget: HTMLElement;
};

// Connects to data-controller="article-list-filter"
export default class extends Controller {
  static targets = [
    "keyword",
    "searchInputSp",
    "hiddenSort",
    "hiddenCategoryId",
    "hiddenDays",
    "hiddenFeaturePageKey",
    "articleSearchForm",
    "unreadCheckboxDiv",
    "unreadCheckboxLabel",
    "unreadCheckboxInput",
  ];

  declare readonly keywordTarget: HTMLInputElement;
  declare readonly searchInputSpTarget: HTMLInputElement;
  declare readonly hiddenSortTarget: HTMLInputElement;
  declare readonly hiddenCategoryIdTarget: HTMLInputElement;
  declare readonly hiddenDaysTarget: HTMLInputElement;
  declare readonly hiddenFeaturePageKeyTarget: HTMLInputElement;
  declare readonly articleSearchFormTarget: HTMLFormElement;
  declare readonly unreadCheckboxDivTarget: HTMLElement;
  declare readonly unreadCheckboxLabelTarget: HTMLElement;
  declare readonly unreadCheckboxInputTarget: HTMLInputElement;

  /**
   * 絞り込み条件での記事検索
   * SP用検索ボックスに検索条件を入力した場合は、event.params.spMode に true が入る
   */
  searchArticles(event: SearchEvent): void {
    event.preventDefault();
    if ("keyCode" in event && event.keyCode === 229) return;

    const currentTarget = event.currentTarget;
    if (!currentTarget) return;

    // SPの際の処理(呼び出し側でdata-article-list-filter-sp-mode-param="true"を付与している)
    if (event.params.spMode) {
      if (!event.currentTarget) return;

      const clickedKeyword = event.currentTarget.textContent;
      const spKeyword = this.searchInputSpTarget.value;
      // 検索履歴からの検索時の場合はそれを使用それ以外の場合は、検索ボックスに保持されている値を使用
      // 検索ボックスクリック時にはclickedKeywordは空文字となる
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      this.keywordTarget.value = clickedKeyword || spKeyword;
      // キーワードを保存
      this.saveKeyword(this.keywordTarget.value);
    }

    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    searchParams.set('sort_key', this.hiddenSortTarget.value);
    searchParams.set('category_id', this.hiddenCategoryIdTarget.value);
    searchParams.set('keyword', this.keywordTarget.value);
    searchParams.set('days', this.hiddenDaysTarget.value);
    // パラメータのpageをリセット
    searchParams.delete('page');
    window.location.href = '/search?' + searchParams.toString();
  }

  // カテゴリをクリックした際の処理
  onClickCategory(e: SearchEvent): void {
    e.preventDefault(); // aタグクリックによる挙動をストップし、jsでURLを組み立てる

    // パラメータのcategory_idをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('category_id');

    // パラメータのpageをリセット
    params.delete('page');

    // カテゴリのリンクをクリックしたときidが入る(「すべて」の際は空文字)
    const categoryId = e.params.categoryId;
    if (categoryId) params.set('category_id', categoryId);
    window.location.href = '/search?' + params.toString();
  }

  // 投稿日の絞り込みをクリックした際の処理
  onClickDays(e: SearchEvent): void {
    e.preventDefault(); // aタグクリックによる挙動をストップし、jsでURLを組み立てる

    // パラメータのdaysをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('days');

    // パラメータのpageをリセット
    params.delete('page');

    // 投稿日絞り込みをクリックしたときの日数が入る(「すべて」の際は空文字)
    const days = e.params.days;
    if (Number(days) > 0) params.set('days', days);
    window.location.href = '/search?' + params.toString();
  }

  // 人気タグをクリックした際の処理
  onClickTag(e: SearchEvent): void {
    e.preventDefault(); // aタグクリックによる挙動をストップし、jsでURLを組み立てる

    // パラメータのkeywordに追加
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);

    // パラメータのpageをリセット
    params.delete('page');

    // #付きでクリックされたキーワードを受け取る
    const tag = e.params.tag;
    if (tag) {
      params.delete('keyword')
      params.set('keyword', tag);
    }
    window.location.href = '/search?' + params.toString();
  }

  // 未読の記事のみ表示をクリックした際の処理
  onClickUnreadCheck(e: Event): void {
    e.preventDefault();
    if(e.currentTarget === null) return;

    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);

    // 未読かどうかのチェックボックスを切り替える
    this.unreadCheckboxInputTarget.checked = !this.unreadCheckboxInputTarget.checked;

    // この処理自体はなくても良いが、ページの読み込み完了までの見た目がスムーズになるようにしている。
    if (this.unreadCheckboxInputTarget.checked) {
      this.unreadCheckboxDivTarget.classList.add('is_current');
      this.unreadCheckboxLabelTarget.classList.add('is_current');
    } else {
      this.unreadCheckboxDivTarget.classList.remove('is_current');
      this.unreadCheckboxLabelTarget.classList.remove('is_current');
    }

    // パラメータを追加
    searchParams.set('unread', `${this.unreadCheckboxInputTarget.checked}`);
    // パラメータのpageをリセット
    searchParams.delete('page');
    window.location.href = currentUrl.pathname + '?' + searchParams.toString();
  }

  /**
   * 検索キーワードをローカルストレージに保存
   */
  saveKeyword(keyword: string): void {
    const maxLength = 5; // 最大保存件数
    const serializedArray: string | null = localStorage.getItem("keywordArray");

    const keywordArray: string[] = serializedArray ? JSON.parse(serializedArray) : []

    // すでに保存済のワードであれば先頭に移動させるために一旦削除
    const keyIndex: number = keywordArray.indexOf(keyword);
    if (keyIndex > -1) keywordArray.splice(keyIndex, 1);

    // 先頭に追加
    keywordArray.unshift(keyword);

    // Max５件超過分は削除
    if (keywordArray.length > maxLength) keywordArray.splice(maxLength);

    // 保存
    const newSerializedArray: string = JSON.stringify(keywordArray);
    localStorage.setItem("keywordArray", newSerializedArray);
  }
}
