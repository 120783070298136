import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import { internalApiClient } from "src/libs/axios/internalApiClient";
import { showAnimatedFlashMessage } from "src/utils/flashMessage";

import type { Bank, Branch } from "src/types";

// Connects to data-controller="payment-account"
export default class PaymentAccountController extends Controller {
  static targets = [
    "bankModalButton",
    "branchModalButton",
    "hiddenBankCode",
    "hiddenBankName",
    "hiddenBranchCode",
    "hiddenBranchName",
    "bankListsUl",
    "bankItem",
    "branchListsUl",
    "branchItem",
    "enableIrcInput", // Irc: Invoice Registration Code（インボイス登録番号）
    "ircInputBlock",
    "ircInput",
    "bankInput",
    "branchInput",
    "submitButton",
  ];

  declare readonly bankModalButtonTarget: HTMLButtonElement;
  declare readonly branchModalButtonTarget: HTMLButtonElement;
  declare readonly hiddenBankCodeTarget: HTMLInputElement;
  declare readonly hiddenBankNameTarget: HTMLInputElement;
  declare readonly hiddenBranchCodeTarget: HTMLInputElement;
  declare readonly hiddenBranchNameTarget: HTMLInputElement;
  declare readonly bankListsUlTarget: HTMLUListElement;
  declare readonly bankItemTargets: HTMLElement[];
  declare readonly branchListsUlTarget: HTMLUListElement;
  declare readonly branchItemTargets: HTMLElement[];
  declare readonly enableIrcInputTarget: HTMLInputElement;
  declare readonly ircInputTarget: HTMLInputElement;
  declare readonly ircInputBlockTarget: HTMLDivElement;
  declare readonly bankInputTarget: HTMLInputElement;
  declare readonly branchInputTarget: HTMLInputElement;
  declare readonly submitButtonTarget: HTMLInputElement;

  declare selectBankModal: Modal;
  declare selectBranchModal: Modal;
  declare deleteBankModal: Modal;

  // 金融機関/支店検索のキーボード入力において、キー押下時に入力内容の変化があるかの判定用に保持する文字列
  declare bankSearchInput: string;
  declare branchSearchInput: string;

  connect(): void {
    this.selectBankModal = new Modal("#selectBankModal");
    this.selectBranchModal = new Modal("#selectBankBranchModal");
    this.deleteBankModal = new Modal("#deleteBankModal");
    this.bankSearchInput = "";
    this.branchSearchInput = "";
    showAnimatedFlashMessage();
  }

  /**
   * 金融機関検索モーダルを表示する
   */
  showBankSelectModal(e: Event): void {
    const { target } = e;
    if (!(target instanceof HTMLButtonElement)) return;
    if (target.classList.contains("icon_reset")) return;
    this.selectBankModal.show();
  }

  /**
   * 支店検索モーダルを表示する
   */
  showBranchSelectModal(e: Event): void {
    const { target } = e;
    if (!(target instanceof HTMLButtonElement)) return;
    if (target.classList.contains("icon_reset")) return;
    this.selectBranchModal.show();
  }

  /**
   * 口座削除モーダルを表示する
   */
  showDeleteBankModal(e: Event): void {
    const { target } = e;
    if (!(target instanceof HTMLButtonElement)) return;
    this.deleteBankModal.show();
  }

  /**
   * 選択した金融機関情報をフォームに反映する
   */
  async setBank(e: Event): Promise<void> {
    const { target } = e;
    if (!(target instanceof HTMLLIElement)) return;
    const bankCode = target.dataset.bankCode;
    const bankName = target.textContent;
    if (bankCode) this.hiddenBankCodeTarget.value = bankCode;
    if (bankName) {
      this.hiddenBankNameTarget.value = bankName;
      const spanStr = `<span>${bankName}</span><i class="fa-solid fa-circle-xmark icon_reset" data-action="click->payment-account#clearBank"></i>`;
      this.bankModalButtonTarget.innerHTML = spanStr;
    }
    this.bankItemTargets.forEach(li => {
      li.classList.remove('is_select')
    })
    target.classList.add('is_select')
    this.selectBankModal.hide();
    this.clearBranch();
    this.branchModalButtonTarget.classList.remove("is_disabled");
    await this.fetchBranchData("");
  }

  /**
   * 選択した金融機関情報を初期化する
   */
  clearBank(e: Event): void {
    const { currentTarget } = e;
    if (!(currentTarget instanceof HTMLElement)) return;
    this.bankModalButtonTarget.innerHTML = "";
    this.bankModalButtonTarget.innerHTML = `<i class="fa-solid fa-magnifying-glass"></i>金融機関名を検索`;
    this.hiddenBankCodeTarget.value = "";
    this.hiddenBankNameTarget.value = "";
    this.branchModalButtonTarget.classList.add("is_disabled");
  }

  /**
   * 金融機関検索モーダルの入力値に応じて、金融機関検索を実行する
   */
  async fetchBankData(e: KeyboardEvent): Promise<void> {
    // 日本語入力が確定していない場合はreturn
    const { target } = e;
    if (!(target instanceof HTMLInputElement)) return;
    const freeword = target.value;

    // cmdキーなど、文字入力/削除を行わないキーが押下された時も、inputタグの入力値が変わらないのでreturn
    if (target.value === this.bankSearchInput) return;

    this.bankSearchInput = freeword;
    const result = await internalApiClient.get<Bank[]>("/api/internal/v1/banks", {
      params: { freeword },
    });
    const liElementsStr = result.data.reduce((prev, bank) => {
      const li = `<li class="bank_item ${this.hiddenBankCodeTarget.value === bank.code ? 'is_select' : ''}" data-bank-code="${bank.code}" data-action="click->payment-account#setBank" data-payment-account-target="bankItem">${bank.name}</li>`;
      return prev + li;
    }, "");

    this.bankListsUlTarget.innerHTML = liElementsStr;
  }

  /**
   * 支店名フォームの入力に応じて、支店検索を実行する
   */
  async onChangeBranchInput(e: KeyboardEvent): Promise<void> {
    // 日本語入力が確定していない場合はreturn
    const { target } = e;
    if (!(target instanceof HTMLInputElement)) return;
    const freeword = target.value;

    // cmdキーなど、文字入力/削除を行わないキーが押下された時も、inputタグの入力値が変わらないのでreturn
    if (target.value === this.branchSearchInput) return;

    this.branchSearchInput = freeword;
    await this.fetchBranchData(freeword);
  }

  /**
   * 支店検索APIから支店一覧を取得する
   */
  async fetchBranchData(freeword: string): Promise<void> {
    const result = await internalApiClient.get<Branch[]>(
      `/api/internal/v1/banks/${this.hiddenBankCodeTarget.value}/branches`,
      {
        params: { freeword },
      },
    );
    const liElementsStr = result.data.reduce((prev, branch) => {
      const li = `<li class="bank_item" data-branch-code="${branch.code}" data-action="click->payment-account#setBranch" data-payment-account-target="branchItem">${branch.name}</li>`;
      return prev + li;
    }, "");
    this.branchListsUlTarget.innerHTML = liElementsStr;
  }

  /**
   * 選択した支店情報をフォームに反映する
   */
  setBranch(e: Event): void {
    const { target } = e;
    if (!(target instanceof HTMLLIElement)) return;
    const branchCode = target.dataset.branchCode;
    const branchName = target.textContent;
    if (branchCode) {
      this.hiddenBranchCodeTarget.value = branchCode;
    }
    if (branchName) {
      this.hiddenBranchNameTarget.value = branchName;
      const spanStr = `<span>${branchName}</span><i class="fa-solid fa-circle-xmark icon_reset" data-action="click->payment-account#handleClickClearBranchButton"></i>`;
      this.branchModalButtonTarget.innerHTML = spanStr;
    }
    this.branchItemTargets.forEach(li => {
      li.classList.remove('is_select')
    })
    target.classList.add('is_select')
    this.selectBranchModal.hide();
  }

  /**
   * ×ボタンクリック時にclearBranchを実行する
   */
  handleClickClearBranchButton(e: Event): void {
    const { currentTarget } = e;
    if (!(currentTarget instanceof HTMLElement)) return;
    this.clearBranch();
  }

  /**
   * 選択した支店情報を初期化する
   */
  clearBranch(): void {
    this.branchModalButtonTarget.innerHTML = "";
    this.branchModalButtonTarget.innerHTML = `<i class="fa-solid fa-magnifying-glass"></i>支店名を検索`;
    this.hiddenBranchCodeTarget.value = "";
    this.hiddenBranchNameTarget.value = "";
  }

  /**
   * インボイス登録番号有無のラジオボタンに応じて、インボイス登録番号欄の表示/非表示を切り替え
   */
  toggleInvoiceInputVisibility(e: Event): void {
    const invoiceRegistrationEnabled = this.enableIrcInputTarget.checked;
    // TODO mockで更新あったらアニメーション付ける
    if (invoiceRegistrationEnabled) {
      this.ircInputBlockTarget.style.display = "";
    } else {
      this.ircInputBlockTarget.style.display = "none";
      setTimeout(() => {
        this.ircInputTarget.value = "";
      }, 100);
    }
  }

  async clearBankInput(): Promise<void> {
    this.bankInputTarget.value = "";

    // ×ボタンクリック時にfetchBankDataを呼ぶため、KeybordEventを偽造
    const artificialEvent = new KeyboardEvent("keyup");
    Object.defineProperty(artificialEvent, "target", {
      writable: false,
      value: this.bankInputTarget,
    });

    await this.fetchBankData(artificialEvent);
  }

  async clearBranchInput(): Promise<void> {
    this.branchInputTarget.value = "";
    await this.fetchBranchData(this.branchInputTarget.value);
  }

  enableSubmitButton(e: Event): void {
    const agreeToMinorConfirmation = document.querySelector('#agreeToMinorConfirmation') as HTMLInputElement
    // 未成年用の法定代理人チェックボックスが存在し、チェックがついていない時にはsubmitButtonをアクティブにしない
    if(agreeToMinorConfirmation && !agreeToMinorConfirmation.checked){
      return
    }
    this.submitButtonTarget.classList.remove("is_disable");
  }
}
