import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="bookmark-filter-sp"
// SPのポップアップモーダルで使用する処理はこちらにまとめる(controllerをアタッチする範囲を狭く保つため)
export default class extends Controller {
  // ポップアップから開いたカテゴリをタップした時に実行される関数
  // カテゴリの条件を追加する
  onTapCategory(e: Event): void {
    // aタグクリックによる挙動をストップし、jsでURLを組み立てる
    e.preventDefault();

    // パラメータのcategory_idをリセット
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('category_id');

    // パラメータのpageをリセット
    params.delete('page');

    // カテゴリのリンクをクリックしたときidが入る(「すべて」の際は空文字)
    const target = (e.currentTarget as HTMLElement);
    const categoryId = target.dataset.bookmarkFilterCategoryIdParam;
    if (categoryId) params.set('category_id', categoryId);
    window.location.href = '/bookmarks?' + params.toString();
  }

  // SPのカテゴリ選択ポップアップのメインカテゴリ開閉動作
  toggleMainCategory(e: Event): void {
    const target = e.currentTarget as HTMLElement
    const mainCategoryLi = target.parentElement;
    mainCategoryLi?.classList.toggle('is_open');
  }
}
