import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="my-reviews"
export default class MyReviewsController extends Controller {
  static targets = ["updatedReview"];

  declare readonly updatedReviewTarget: HTMLElement;

  scrollToUpdatedReview(e: Event): void {
    e.preventDefault();
    if (this.updatedReviewTarget) {
      const yOffset = this.updatedReviewTarget.getBoundingClientRect().top + window.scrollY;

      // ヘッダー部分と被らないようにするために100ずらす
      window.scrollTo({ top: yOffset - 100, behavior: 'smooth' });
    }
  }
}
