import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="review-box"
export default class ReviewBoxController extends Controller {
  static targets = ["reviewTextBox"];

  declare readonly reviewTextBoxTargets: HTMLElement[];

  connect(): void {
    this.hideViewMoreButtonIfNotEnoughLines();
  }

  // レビューが長い場合の「もっと見る」と「閉じる」を切り替える
  toggleReviewText(event: Event): void {
    const button = event.currentTarget;
    if (!(button instanceof HTMLElement)) return;

    if (!button.previousElementSibling) return;

    let reviewTextBox = button.previousElementSibling;
    let counter = 0;

    while (!reviewTextBox.classList.contains('review_text_box') && counter < 2) {
      if (reviewTextBox.previousElementSibling == null) return;

      reviewTextBox = reviewTextBox.previousElementSibling;
      counter++;
    }

    if (!(reviewTextBox instanceof HTMLElement)) return;

    if (reviewTextBox.classList.contains("is_close")) {
      reviewTextBox.style.maxHeight = reviewTextBox.scrollHeight + 'px';
      reviewTextBox.classList.replace('is_close', 'is_open');
    } else if (reviewTextBox.classList.contains("is_open")) {
      reviewTextBox.style.maxHeight = "65px";
      reviewTextBox.classList.replace('is_open', 'is_close');
    }
  }

  // 4行未満の場合は、「もっと見る」ボタンを表示しない
  hideViewMoreButtonIfNotEnoughLines(): void {
    this.reviewTextBoxTargets.forEach((element) => {
      const boxHeight: number = element.scrollHeight;
      if (boxHeight < 65) {
        element.classList.add('is_btn_less');
      } else {
        element.classList.add('is_close');
        element.style.maxHeight = "65px";
      }
    });
  }
}
