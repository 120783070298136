import { Controller } from "@hotwired/stimulus";
import Splide from "@splidejs/splide";
import "@splidejs/splide/css";
import "@splidejs/splide/css/core";

// Connects to data-controller="main-visual-carousel"
export default class MainVisualController extends Controller {
  static targets = ["mainVisualCarousel"];

  declare readonly mainVisualCarouselTarget: HTMLElement;

  declare splide: Splide;

  connect(): void {
    this.splide = new Splide(this.mainVisualCarouselTarget, {
      type: "loop",
      autoWidth: true,
      height: "240px",
      gap: "16px",
      focus: "center",
      autoplay: true,
      interval: 4000,
      updateOnMove: true,
      i18n: {
        first: "前のスライドに移動する",
        last: "次のスライドに移動する",
      },
      breakpoints: {
        768: {
          autoWidth: false,
          height: "auto",
          gap: "8px",
          padding: "5%",
        },
      },
    });

    this.splide.mount();
  }
}
