import { Controller } from "@hotwired/stimulus";
import { internalApiClient } from "src/libs/axios/internalApiClient";

export default class extends Controller {
  connect(): void {
    this.triggerArticleViewOnScroll = this.triggerArticleViewOnScroll.bind(this);
    window.addEventListener("scroll", this.triggerArticleViewOnScroll);
  }

  triggerArticleViewOnScroll(): void {
    void this.logArticleView();
    // 呼び出すのは1度だけで良いので、logArticleViewの呼び出し後にイベントを削除する
    window.removeEventListener("scroll", this.triggerArticleViewOnScroll);
  };

  async logArticleView(): Promise<void> {
    // 既読のログがある場合は処理を行わない
    const alreadyReadParam = (this.element as HTMLElement).dataset.alreadyReadParam;
    if (alreadyReadParam === "true") {
      return;
    }

    const articleKey = (this.element as HTMLElement).dataset.articleKeyParam;
    if (!articleKey) {
      console.error("articleKeyが見つかりませんでした");
      return;
    }

    try {
      await internalApiClient.post(`/api/internal/v1/articles/${articleKey}/views`);
    } catch (error) {
      console.error("既読とPVの記録を行う内部APIの呼び出しに失敗しました:", error);
    }
  }
}
